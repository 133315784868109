import axios, { AxiosResponse } from "axios";
import {ProductFilterCollection} from '@/types';
import {OffersFiltersList} from "@/store/apiroutes/FilterOffersRoutes";
import {FilterOffersInfo, OfferProductInfo, SearchInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {
    FilteredProductsList,
    OfferProductsList,
    OfferFormFields, OfferCategoriesProductsList,
    CreateOfferProduct, UpdateOfferProduct, DeleteOfferProduct, SearchOfferProducts
} from "@/store/apiroutes/OffersRoutes";

async function getOffersFiltersList(payload: number) {
    let respObj = OffersFiltersList(payload)
    try {
        // @ts-ignore
        const response: AxiosResponse<ProductFilterCollection> = await axios[
            respObj.method
        ](
            respObj.url,
            respObj.options
        );
        return {filters: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getFilteredProducts(payload:{ filterOffersInfo: FilterOffersInfo, category: number, page?: number}) {
    const payloadObj = FilteredProductsList(payload.filterOffersInfo, payload.category, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            payloadObj.method
            ](
            payloadObj.url,
            payloadObj.payload,
            payloadObj.options
        );
        return {
            headers: response.data.headers,
            products: response.data.products,
            filters: response.data.filters,
            sorted: response.data.sorted
        };
    } catch (error) {
        throw error;
    }
}

async function getOffersProductsList(payload: { category: number, page?: number }) {
    const payloadObj = OfferProductsList(payload.category, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            payloadObj.method
            ](
            payloadObj.url,
            payloadObj.options
        );
        return {
            products: response.data
        };
    } catch (error) {
        throw error;
    }
}

async function getOffersFormFieldsList(category: number) {
    let rquestObj = OfferFormFields(category);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            rquestObj.method
            ](
            rquestObj.url,
            rquestObj.options
        );
        return {
            fields: response.data
        };
    } catch (error) {
        throw error;
    }
}

async function createOfferProducts(payload: {offerProductInfo: OfferProductInfo, category: number } ) {
    const payloadObj = CreateOfferProduct(payload.offerProductInfo, payload.category);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            payloadObj.method
            ](
            payloadObj.url,
            payloadObj.payload,
            payloadObj.options
        );
        return {
            products: response.data
        } ;
    } catch (error) {
        throw error;
    }
}

async function updateOfferProducts(payload: {offerProductInfo: OfferProductInfo, offerProductId: number, category: number}) {
    const payloadObj = UpdateOfferProduct(payload.offerProductInfo, payload.offerProductId, payload.category);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            payloadObj.method
            ](
            payloadObj.url,
            payloadObj.payload,
            payloadObj.options
        );
        return {
            products: response.data
        } ;
    } catch (error) {
        throw error;
    }
}

async function deleteOfferProducts(payload: { productId: number, category: number }) {
    const payloadObj = DeleteOfferProduct(payload.productId, payload.category);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            payloadObj.method
            ](
            payloadObj.url,
            payloadObj.options
        );
        return {
            products: response.data
        };
    } catch (error) {
        throw error;
    }
}

async function getOffersCategoriesFiltersList() {
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            OfferCategoriesProductsList.method
            ](
            OfferCategoriesProductsList.url,
            OfferCategoriesProductsList.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function searchOfferProducts(payload: { searchInfo: SearchInfo, category: number, page?: number }) {
    const payloadObj = SearchOfferProducts(payload.searchInfo, payload.category, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            payloadObj.method
            ](
            payloadObj.url,
            payloadObj.payload,
            payloadObj.options
        );
        return {
            products: response.data
        } ;
    } catch (error) {
        throw error;
    }
}

export default {
    getOffersFiltersList,
    getFilteredProducts,
    getOffersProductsList,
    getOffersFormFieldsList,
    createOfferProducts,
    updateOfferProducts,
    deleteOfferProducts,
    getOffersCategoriesFiltersList,
    searchOfferProducts
};