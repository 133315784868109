import { Module } from 'vuex';

import service from '@/store/services/users-service';
import { UsersCollection } from '@/types';

interface UsersState {
    usersList: UsersCollection[];
    usersCSV: Blob | null
}

const state: UsersState = {
    usersList: [],
    usersCSV: null
};

const mutations = {
    SET_USERS_LIST: (state: UsersState, usersList: UsersCollection[]) => {
        state.usersList = usersList;
    },
    SET_USERS_CSV_FILE: (state: UsersState, file: any) => {
        state.usersCSV = file
    }
};

const actions = {
    userslist({ commit, dispatch }: any, params: any) {
        return service.getUsers(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    newUser({ commit, dispatch }: any, params: any) {
        return service.createUser(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    updateUser({ commit, dispatch }: any, params: any) {
        return service.updateUser(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    deactivateUser({ commit, dispatch }: any, params: any) {
        return service.deactivateUser(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    sortUsersByField({ commit, dispatch }: any, params: any) {
        return service.sortUsersByField(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    searchUsers({ commit, dispatch }: any, params: any) {
        return service.searchUsers(params)
            .then(({ users }: any) => {
                commit('SET_USERS_LIST', users);
            });
    },
    getUsersCSVfile({ commit, dispatch }: any, params: any) {
        return service.getCSVUsersFile()
            .then(({ file }: any) => {
                commit('SET_USERS_CSV_FILE', file);
            });
    }
};

const getters = {
    usersList: (state: UsersState) => state.usersList,
    usersCSV: (state: UsersState) => state.usersCSV
};

const users: Module<UsersState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default users;
