
import axios, { AxiosResponse } from "axios";
import {
    GetSortedBirthdayTextSlides,
    GetSortedCategories, GetSortedContactCategories,
    GetSortedContacts,
    GetSortedDashboardWeeklyReviews,
    GetSortedDocuments, GetSortedProductOffers, GetSortedSpecialOffers, GetSortedTabArticles, GetSortedTabCompanies,
    GetSortedTabDocs, GetSortedTextSlides,
    GetSortingFieldsList, UpdateSortingFieldName
} from "@/store/apiroutes/SortingFieldsRoutes";
import {SortByFieldInfo, UpdateFieldNameInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {
    CategoriesCollection,
    Category,
    ContactCategoryCollection,
    DashBoardSpetialOfferCollection, DashboardTextSlidesCollection,
    TabItemsCollection
} from "@/types";

async function getUsersSortingFields() {
    const sortingFieldsObj = GetSortingFieldsList('users');
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getDocumentsSortingFields(params?: number) {
    const sortingFieldsObj = GetSortingFieldsList('Document', params);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getTabsDocumentsSortingFields(params?: number) {
    const sortingFieldsObj = GetSortingFieldsList('TabDocument', params);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getTabsCompaniesSortingFields(params?: number) {
    const sortingFieldsObj = GetSortingFieldsList('TabCompany', params);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getContactsSortingFields(params?: number) {
    const sortingFieldsObj = GetSortingFieldsList('Contact', params);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getCategoriesSortingFields() {
    const sortingFieldsObj = GetSortingFieldsList('Category');
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getTextSlidesSortingFields() {
    const sortingFieldsObj = GetSortingFieldsList('DashBoardTextSlider');
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getContactCategoriesSortingFields() {
    const sortingFieldsObj = GetSortingFieldsList('ContactCategory');
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function getSpecialOffersSortingFields() {
    const sortingFieldsObj = GetSortingFieldsList('DashboardSpecialOffer');
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortDocuments(payload: {sortingInfo: SortByFieldInfo, categoryId: number, page?: number}) {
    const sortedObj = GetSortedDocuments(payload.sortingInfo, payload.categoryId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<Category> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortContacts(payload: {sortingInfo: SortByFieldInfo, categoryId: number, page?: number}) {
    const sortedObj = GetSortedContacts(payload.sortingInfo, payload.categoryId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategory> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {category: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortTabDocuments(payload: {sortingInfo: SortByFieldInfo, tabId: number, page?: number}) {
    const sortedObj = GetSortedTabDocs(payload.sortingInfo, payload.tabId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortTabCompanies(payload: {sortingInfo: SortByFieldInfo, tabId: number, page?: number}) {
    const sortedObj = GetSortedTabCompanies(payload.sortingInfo, payload.tabId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortCategories(payload: SortByFieldInfo) {
    const sortedObj = GetSortedCategories(payload);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortContactCategories(payload: SortByFieldInfo) {
    const sortedObj = GetSortedContactCategories(payload);
    try {
        // @ts-ignore
        const response: AxiosResponse<ContactCategoryCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {categories: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortSpecialOffers(payload: {sortingInfo: SortByFieldInfo, page?: number}) {
    const sortedObj = GetSortedSpecialOffers(payload.sortingInfo, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashBoardSpetialOfferCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {offers: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortTextSlides(payload: {sortingInfo: SortByFieldInfo, page?: number}) {
    const sortedObj = GetSortedTextSlides(payload.sortingInfo, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {dashboard_text_slides: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortBirthdayTextSlides(payload: {sortingInfo: SortByFieldInfo, page?: number}) {
    const sortedObj = GetSortedBirthdayTextSlides(payload.sortingInfo, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {dashboard_text_slides: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateDocumentsSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'Document', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateTabDocumentsSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'TabDocument', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateTabCompaniesSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'TabCompany', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateContactsSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'Contact', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateCategoriesSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'Category', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateTextSlidesSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'DashBoardTextSlider', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateContactCategoriesSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'ContactCategory', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateSpecialOffersSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'DashboardSpecialOffer', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}


async function sortProductOffers(payload: {sortingInfo: SortByFieldInfo, category: number, page?: number}) {
    const sortedObj = GetSortedProductOffers(payload.sortingInfo, payload.category, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {sortedProductOffers: response.data} ;
    } catch (error) {
        throw error;
    }
}



async function updateProductOffersSortingFields(payload: {fieldsInfo: UpdateFieldNameInfo, fieldId: number}) {
    const sortingFieldsObj = UpdateSortingFieldName(payload.fieldsInfo, 'Offer', payload.fieldId);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.payload,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}


async function sortDashboardWeeklyReviews(payload: {sortingInfo: SortByFieldInfo, page?: number}) {
    const sortedObj = GetSortedDashboardWeeklyReviews(payload.sortingInfo, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {reviews: response.data} ;
    } catch (error) {
        throw error;
    }
}



async function getDashboardWeeklyRewiewsFields(params?: number) {
    const sortingFieldsObj = GetSortingFieldsList('DashboardWeeklyReview', params);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortingFieldsObj.method
            ](
            sortingFieldsObj.url,
            sortingFieldsObj.options
        );
        return {fields: response.data} ;
    } catch (error) {
        throw error;
    }
}


async function sortTabArticles(payload: {sortingInfo: SortByFieldInfo, tabId: number, page?: number}) {
    const sortedObj = GetSortedTabArticles(payload.sortingInfo, payload.tabId, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<TabItemsCollection> = await axios[
            sortedObj.method
            ](
            sortedObj.url,
            sortedObj.payload,
            sortedObj.options
        );
        return {items: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
   getUsersSortingFields,
    getDocumentsSortingFields,
    getTabsDocumentsSortingFields,
    getTabsCompaniesSortingFields,
    getContactsSortingFields,
    getCategoriesSortingFields,
    getTextSlidesSortingFields,
    getContactCategoriesSortingFields,
    getSpecialOffersSortingFields,
    sortDocuments,
    sortContacts,
    sortTabDocuments,
    sortTabCompanies,
    sortCategories,
    sortContactCategories,
    sortSpecialOffers,
    sortTextSlides,
    sortBirthdayTextSlides,
    updateDocumentsSortingFields,
    updateTabDocumentsSortingFields,
    updateTabCompaniesSortingFields,
    updateContactsSortingFields,
    updateCategoriesSortingFields,
    updateTextSlidesSortingFields,
    updateContactCategoriesSortingFields,
    updateSpecialOffersSortingFields,
    sortProductOffers,
    updateProductOffersSortingFields,
    sortDashboardWeeklyReviews,
    getDashboardWeeklyRewiewsFields,
    sortTabArticles
};