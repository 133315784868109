import { Module } from 'vuex';

import service from '@/store/services/contact_companies-service';

interface ContactCompaniesState {
    contact_companies: [];
    contact_tabs: [];
    contact_tab_content: [];
}

const state: ContactCompaniesState = {
    contact_companies: [],
    contact_tabs: [],
    contact_tab_content: [],
};

const mutations = {
    SET_CONTACT_COMPANIES_LIST: (state: ContactCompaniesState, contact_companies: []) => {
        state.contact_companies = contact_companies;
    },
    SET_CONTACT_TABS_LIST: (state: ContactCompaniesState, contact_tabs: []) => {
        state.contact_tabs = contact_tabs;
    },
    SET_CONTACT_TAB_ITEMS_LIST: (state: ContactCompaniesState, contact_tab_content: []) => {
        state.contact_tab_content = contact_tab_content;
    },
};

const actions = {
    contactCompanieslist({ commit, dispatch }: any, params: any) {
        return service.getContactCompaniesList()
            .then(({ contact_companies }: any) => {
                commit('SET_CONTACT_COMPANIES_LIST', contact_companies);
            });
    },
    contactTabslist({ commit, dispatch }: any, params: any) {
        return service.getContactTabsList(params)
            .then(({ contact_tabs }: any) => {
                commit('SET_CONTACT_TABS_LIST', contact_tabs);
            });
    },
    contactTabItemslist({ commit, dispatch }: any, params: any) {
        return service.getContactTabItemsList(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    sortedContactTabItemslist({ commit, dispatch }: any, params: any) {
        return service.getSortedContactTabItemsList(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    localSearchContactTabItemslist({ commit, dispatch }: any, params: any) {
        return service.getLocalSearcResultItemsList(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    createContactBankDetailsItem({ commit, dispatch }: any, params: any) {
        return service.createContactBankDetailsItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    createContactAddressItem({ commit, dispatch }: any, params: any) {
        return service.createContactAddressItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    createContactPersonItem({ commit, dispatch }: any, params: any) {
        return service.createContactPersonItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    updateContactPersonItem({ commit, dispatch }: any, params: any) {
        return service.updateContactPersonItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    updateContactAddressItem({ commit, dispatch }: any, params: any) {
        return service.updateContactAddressItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    updateContactBankDetailsItem({ commit, dispatch }: any, params: any) {
        return service.updateContactBankDetailsItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    deleteContactPersonItem({ commit, dispatch }: any, params: any) {
        return service.deleteContactPersonItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    deleteContactAddressItem({ commit, dispatch }: any, params: any) {
        return service.deleteContactAddressItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },
    deleteContactBankDetailsItem({ commit, dispatch }: any, params: any) {
        return service.deleteContactBankDetailsItem(params)
            .then(({ contact_tab_content }: any) => {
                commit('SET_CONTACT_TAB_ITEMS_LIST', contact_tab_content);
            });
    },

};

const getters = {
    contact_companies: (state: ContactCompaniesState) => state.contact_companies,
    contact_tabs: (state: ContactCompaniesState) => state.contact_tabs,
    contact_tab_content: (state: ContactCompaniesState) => state.contact_tab_content,
};

const contact_companies_module: Module<ContactCompaniesState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default contact_companies_module;
