import authModule from '@/store/modules/auth';
import {SearchInfo} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL + '/global';
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}


export const GlobalSearch = (searchInfo: SearchInfo, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/search?page=${page}` : `${BaseApiUrl}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}