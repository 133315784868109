import authModule from '@/store/modules/auth';
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const OffersFiltersList = (category: number) => {
    return {
        url: `${BaseApiUrl}/product_filters_list/${category}`,
        method: 'get',
        options: authoptions,
    }
}