import { createStore } from 'vuex';
import users from './modules/users-module';
import roles from './modules/roles-module';
import icons from './modules/icons-module';
import authModule from './modules/auth';
import notificationsModule from './modules/notifications';
import profile from "@/store/modules/profile-module";
import validateFields from "@/store/modules/validateFields-module";
import categories from "@/store/modules/categories-module";
import documents from "@/store/modules/documents-module";
import events from "@/store/modules/events-module";
import eventCategories from "@/store/modules/eventsCategories-module";
import contactCategories from "@/store/modules/contacts-categories-module";
import contacts from "@/store/modules/contacts-module";
import generalTabs from "@/store/modules/general-tabs-module";
import tabDocs from "@/store/modules/tabs-document-module";
import tabCompanies from "@/store/modules/tabs-company-module";
import tabArticles from "@/store/modules/tabs-articles-module";
import tabVideos from "@/store/modules/tabs-videos-module";
import dashboard_text_slides from "@/store/modules/dashboardTextSlide-module";
import dashboard_greetings_slides from "@/store/modules/dashboardGreettingsSlide-module";
import dashboard_spec_offers_slides from "@/store/modules/dashboardSpecOfferSlide-module";
import globalModule from "@/store/modules/global-module";
import sorting_module from "@/store/modules/sorting-module";
import bottom_bar_lists from "@/store/modules/bottom_bar-module";
import dashboardWeeklyReview from "@/store/modules/dashboardWeeklyRewiew-module";
import offersProductsFilters from "@/store/modules/productOffers_filter-module";
import contact_companies_module from "@/store/modules/contact_companies-module";
import companies_info from "@/store/modules/companies_info-module";
import dashboard_carusel from "@/store/modules/dashboard_carusel-module";
export default createStore({
  modules: {
    dashboard_carusel,
    companies_info,
    contact_companies_module,
    bottom_bar_lists,
    dashboardWeeklyReview,
    offersProductsFilters,
    sorting_module,
    globalModule,
    dashboard_greetings_slides,
    dashboard_spec_offers_slides,
    dashboard_text_slides,
    tabVideos,
    tabArticles,
    tabCompanies,
    tabDocs,
    generalTabs,
    eventCategories,
    contactCategories,
    contacts,
    events,
    icons,
    users,
    categories,
    documents,
    roles,
    profile,
    validateFields,
    auth: authModule,
    notifications: notificationsModule,
  },
});