
import axios, { AxiosResponse } from "axios";
import {GlobalSearchCollection} from '@/types';
import {SearchInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {GlobalSearch} from "@/store/apiroutes/GlobalRoutes";

async function getGlobalSearchResult(payload: {searchInfo: SearchInfo, page?: number}) {
    const searchObj = GlobalSearch(payload.searchInfo, payload.page);
    try {
        // @ts-ignore
        const response: AxiosResponse<GlobalSearchCollection> = await axios[
            searchObj.method
        ](
            searchObj.url,
            searchObj.payload,
            searchObj.options
        );
        return {result: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getGlobalSearchResult
};