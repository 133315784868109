import { Module } from 'vuex';
import service from "@/store/services/validateFields-service";

interface ValidFieldsState {
    validateFields: {};
}

const state: ValidFieldsState = {
    validateFields: {},
};

const mutations = {
    SET_VALIDATE_FIELDS: (state: ValidFieldsState, validateFields: any) => {
        state.validateFields = validateFields;
    },
};

const actions = {
    validateFields({ commit, dispatch }: any, params: any) {
        return service.validateField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateCategoriesField({ commit, dispatch }: any, params: any) {
        return service.validateCategoriesField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateEventCategoriesField({ commit, dispatch }: any, params: any) {
        return service.validateEventCategoriesField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateDocumentsField({ commit, dispatch }: any, params: any) {
        return service.validateDocumentsField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateEventsField({ commit, dispatch }: any, params: any) {
        return service.validateEventsField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateContactCategoriesField({ commit, dispatch }: any, params: any) {
        return service.validateContactCategoriesField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateContactsField({ commit, dispatch }: any, params: any) {
        return service.validateContactsField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateGeneralTabsField({ commit, dispatch }: any, params: any) {
        return service.validateGeneralTabsField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateTabDocsField({ commit, dispatch }: any, params: any) {
        return service.validateTabDocsField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateTabCompanyField({ commit, dispatch }: any, params: any) {
        return service.validateTabCompanyField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateTabArticleField({ commit, dispatch }: any, params: any) {
        return service.validateTabArticleField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateTabVideosField({ commit, dispatch }: any, params: any) {
        return service.validateTabVideosField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateDashboardTextField({ commit, dispatch }: any, params: any) {
        return service.validateDashboardTextField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateDashboardGreetingsTypeField({ commit, dispatch }: any, params: any) {
        return service.validateDashboardGreetingsTypeField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateDashboardSpecOfferField({ commit, dispatch }: any, params: any) {
        return service.validateDashboardSpecOfferField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateDashboardWeeklyReportFields({ commit, dispatch }: any, params: any) {
        return service.validateDashboardWeeklyReportFields(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateOfferField({ commit, dispatch }: any, params: any) {
        return service.validateOfferField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateContactPersonField({ commit, dispatch }: any, params: any) {
        return service.validateContactPersonField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateContactAddressField({ commit, dispatch }: any, params: any) {
        return service.validateContactAddressField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateContactBankDetailField({ commit, dispatch }: any, params: any) {
        return service.validateContactBankDetailField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
    validateCompanyInfoItemField({ commit, dispatch }: any, params: any) {
        return service.validateCompanyInfoItemField(params)
            .then(({ validated }: any) => {
                commit('SET_VALIDATE_FIELDS', validated);
            });
    },
};

const getters = {
    validateFields: (state: ValidFieldsState) => state.validateFields,
};

const validateFields: Module<ValidFieldsState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default validateFields;
