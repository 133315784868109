import { Module } from 'vuex';

import service from '@/store/services/bottom_bar-service';

interface BottomBarState {
    financial_assets: [];
    rss_updates: [];
}

const state: BottomBarState = {
    financial_assets: [],
    rss_updates: [],
};

const mutations = {
    SET_FIN_ASSETS_LIST: (state: BottomBarState, financial_assets: []) => {
        state.financial_assets = financial_assets;
    },
    SET_RSS_UPDATES_LIST: (state: BottomBarState, rss_updates: []) => {
        state.rss_updates = rss_updates;
    },
};

const actions = {
    bottomFinancialAssetslists({ commit, dispatch }: any, params: any) {
        return service.getBottomFinancialAssetsList(params)
            .then(({ financial_assets }: any) => {
                commit('SET_FIN_ASSETS_LIST', financial_assets);
            });
    },
    bottomRssUpdateslists({ commit, dispatch }: any, params: any) {
        return service.getRssUpdatesList()
            .then(({ rss_updates }: any) => {
                commit('SET_RSS_UPDATES_LIST', rss_updates);
            });
    },
};

const getters = {
    financial_assets: (state: BottomBarState) => state.financial_assets,
    rss_updates: (state: BottomBarState) => state.rss_updates,
};

const bottom_bar_lists: Module<BottomBarState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default bottom_bar_lists;
