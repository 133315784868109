import authModule from '@/store/modules/auth';
import {
    DashboardWeeklyReviewInfo
} from "@/store/apiPayloadsFiles/payloadsInfo";
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL + `/dashboard_weekly_review`;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}
export const ShowDashboardWeeklyRewiew = {
    url: `${BaseApiUrl}`,
    method: 'get',
    options: authoptions,
}


export const AllDashboardWeeklyRewiews = (page?: number) => {
   return {
       url: page ? `${BaseApiUrl}_all?page=${page}` : `${BaseApiUrl}_all`,
       method: 'get',
       options: authoptions,
   };
}

export const CreateDasboardWeeklyRewiew = (dashboardWeeklyReviewInfo: DashboardWeeklyReviewInfo) => {
    return {
        url: `${BaseApiUrl}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...dashboardWeeklyReviewInfo }
    };
}

export const UpdateDasboardWeeklyRewiew = (dashboardWeeklyReviewId: number, dashboardWeeklyReviewInfo: DashboardWeeklyReviewInfo) => {
    return {
        url: `${BaseApiUrl}/${dashboardWeeklyReviewId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...dashboardWeeklyReviewInfo }
    };
}

export const DeleteDasboardWeeklyRewiew = (dashboardWeeklyReviewId: number) => {
    return {
        url: `${BaseApiUrl}/${dashboardWeeklyReviewId}/delete`,
        method: 'delete',
        options: authoptions,
    };
}