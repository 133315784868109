import { Module } from 'vuex';

import service from '@/store/services/dashboardWeeklyRewiew-service';
import {DashboardWeeklyReview} from "@/types";

interface DashboardWeeklyReviewState {
    dashboardWeeklyReview: DashboardWeeklyReview[];
    
}

const state: DashboardWeeklyReviewState = {
    dashboardWeeklyReview: [],
};

const mutations = {
    SET_DASHBOARD_WEEKLY_REPORT: (state: DashboardWeeklyReviewState, dashboardWeeklyReview: DashboardWeeklyReview[]) => {
        state.dashboardWeeklyReview = dashboardWeeklyReview;
    },
};

const actions = {
    dashboardWeeklyReview({ commit, dispatch }: any, params: any) {
        return service.getDasboardWeeklyReview()
            .then(({ reviews }: any) => {
                commit('SET_DASHBOARD_WEEKLY_REPORT', reviews);
            });
    },
    createDashboardWeeklyReview({ commit, dispatch }: any, params: any) {
        return service.createDashboardWeeklyReview(params)
            .then(({ reviews }: any) => {
                commit('SET_DASHBOARD_WEEKLY_REPORT', reviews);
            });
    },
    updateDashboardWeeklyReview({ commit, dispatch }: any, params: any) {
        return service.updatDashboardWeeklyReview(params)
            .then(({ reviews }: any) => {
                commit('SET_DASHBOARD_WEEKLY_REPORT', reviews);
            });
    },
    deleteDashboardWeeklyReview({ commit, dispatch }: any, params: any) {
        return service.deleteDashboardWeeklyReview(params)
            .then(({ reviews }: any) => {
                commit('SET_DASHBOARD_WEEKLY_REPORT', reviews);
            });
    },
    allDashboardWeeklyReviews({ commit, dispatch }: any, params: any) {
        return service.getAllDasboardWeeklyReviews(params)
            .then(({ reviews }: any) => {
                commit('SET_DASHBOARD_WEEKLY_REPORT', reviews);
            });
    },
};

const getters = {
    dashboardWeeklyReview: (state: DashboardWeeklyReviewState) => state.dashboardWeeklyReview,

};

const dashboardWeeklyReview: Module<DashboardWeeklyReviewState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default dashboardWeeklyReview;
