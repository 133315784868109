
import axios, { AxiosResponse } from "axios";
import {CompanyInfoPayloadInfo, SearchInfo, SortByFieldInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {
    CompaniesInfoList,
    CompanyInfoItems,
    CreateCompanyInfoItem, DeleteCompanyInfoItem, SearchCompanyInfoItems,
    SortedCompanyInfoItems, UpdateCompanyInfoItem
} from "../apiroutes/CompanyInfoRoutes";

async function getCompaniesInfoList() {
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            CompaniesInfoList.method
        ](
            CompaniesInfoList.url,
            CompaniesInfoList.options
        );
        return {companies: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function showCompanyInfoItems(payload: {companyId: number, page?: number, link?: string}) {
    const showCompanyInfoItems = CompanyInfoItems(payload.companyId, payload.page ?? 1, payload.link);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            showCompanyInfoItems.method
        ](
            showCompanyInfoItems.url,
            showCompanyInfoItems.options
        );
        return {company_info_items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function sortCompanyInfoItems(payload: {sortByInfo: SortByFieldInfo, companyId: number, page?: number}) {
    const sortCompanyInfoItemsObj = SortedCompanyInfoItems(payload.sortByInfo, payload.companyId, payload.page ?? 1);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortCompanyInfoItemsObj.method
        ](
            sortCompanyInfoItemsObj.url,
            sortCompanyInfoItemsObj.payload,
            sortCompanyInfoItemsObj.options
        );
        return {company_info_items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function createCompanyInfoItem(payload: {companyInfoPayloadInfo: CompanyInfoPayloadInfo, companyId: number}) {
    const createObj = CreateCompanyInfoItem(payload.companyInfoPayloadInfo, payload.companyId);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            createObj.method
            ](
            createObj.url,
            createObj.payload,
            createObj.options
        );
        return {company_info_items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateCompanyInfoItem(payload: {companyInfoPayloadInfo: CompanyInfoPayloadInfo, companyId: number, itemId: number}) {
    const updateObj = UpdateCompanyInfoItem(payload.companyInfoPayloadInfo, payload.companyId, payload.itemId);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            updateObj.method
            ](
            updateObj.url,
            updateObj.payload,
            updateObj.options
        );
        return {company_info_items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteCompanyInfoItem(payload: {companyId: number, itemId: number}) {
    const deleteObj = DeleteCompanyInfoItem(payload.companyId, payload.itemId);
    try {
        // @ts-ignore
        const response: AxiosResponse<CategoriesCollection> = await axios[
            deleteObj.method
            ](
            deleteObj.url,
            deleteObj.options
        );

        return {company_info_items: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function searchCompanyInfoItems(payload: {searchInfo: SearchInfo, companyId: number, page?: number}) {
    const sortCompanyInfoItemsObj = SearchCompanyInfoItems(payload.searchInfo, payload.companyId, payload.page ?? 1);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            sortCompanyInfoItemsObj.method
            ](
            sortCompanyInfoItemsObj.url,
            sortCompanyInfoItemsObj.payload,
            sortCompanyInfoItemsObj.options
        );
        return {company_info_items: response.data} ;
    } catch (error) {
        throw error;
    }
}

export default {
    getCompaniesInfoList,
    showCompanyInfoItems,
    sortCompanyInfoItems,
    createCompanyInfoItem,
    updateCompanyInfoItem,
    deleteCompanyInfoItem,
    searchCompanyInfoItems,
};