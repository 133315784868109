
import axios, { AxiosResponse } from "axios";
import {BottomFinancialAssetsList, BottomRssUpdatesList} from "@/store/apiroutes/BottomBarRoutes";

async function getBottomFinancialAssetsList(params: number) {
    const routeObj = BottomFinancialAssetsList(params)
    try {
        // @ts-ignore
        const response: AxiosResponse<RolesCollection> = await axios[
            routeObj.method
        ](
            routeObj.url,
            routeObj.options
        );
        return {
            financial_assets: response.data.financial_assets
        } ;
    } catch (error) {
        throw error;
    }
}

async function getRssUpdatesList() {
    try {
        // @ts-ignore
        const response: AxiosResponse<RolesCollection> = await axios[
            BottomRssUpdatesList.method
        ](
            BottomRssUpdatesList.url,
            BottomRssUpdatesList.options
        );
        return {
            rss_updates: response.data.rss_updates
        } ;
    } catch (error) {
        throw error;
    }
}

export default {
    getBottomFinancialAssetsList,
    getRssUpdatesList,
};