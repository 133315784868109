import authModule from '@/store/modules/auth';
import {SortByFieldInfo, UpdateFieldNameInfo} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}

export const GetSortingFieldsList = (modelName: string, categoryId?: number) => {

    return {
        url: categoryId ? `${BaseApiUrl}/get_sorting_fields/${modelName}/${categoryId}` : `${BaseApiUrl}/get_sorting_fields/${modelName}`,
        method: 'get',
        options: authoptions,
    }
}

export const GetSortedDocuments = (sortingInfo: SortByFieldInfo, categoryId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/documents/${categoryId}/sorting?page=${page}` : `${BaseApiUrl}/documents/${categoryId}/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedContacts = (sortingInfo: SortByFieldInfo, categoryId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/contacts/${categoryId}/sorting?page=${page}` : `${BaseApiUrl}/contacts/${categoryId}/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedTabDocs = (sortingInfo: SortByFieldInfo, categoryId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-documents/${categoryId}/sorting?page=${page}` : `${BaseApiUrl}/tabs-documents/${categoryId}/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedTabCompanies = (sortingInfo: SortByFieldInfo, categoryId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-companies/${categoryId}/sorting?page=${page}` : `${BaseApiUrl}/tabs-companies/${categoryId}/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedCategories = (sortingInfo: SortByFieldInfo) => {
    return {
        url: `${BaseApiUrl}/categories/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedContactCategories = (sortingInfo: SortByFieldInfo) => {
    return {
        url: `${BaseApiUrl}/contact-categories/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedSpecialOffers = (sortingInfo: SortByFieldInfo, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/dashboard_special_offers/sorting?page=${page}` : `${BaseApiUrl}/dashboard_special_offers/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedTextSlides = (sortingInfo: SortByFieldInfo, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/dashboard_text_slider/sorting?page=${page}` : `${BaseApiUrl}/dashboard_special_offers/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedBirthdayTextSlides = (sortingInfo: SortByFieldInfo, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/dashboard_text_slider_birthday/sorting?page=${page}` : `${BaseApiUrl}/dashboard_text_slider_birthday/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const UpdateSortingFieldName = (fieldInfo: UpdateFieldNameInfo, modelName: string, fieldId: number) => {
    return {
        url: `${BaseApiUrl}/sorting_field/${modelName}/${fieldId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldInfo }
    };
}

export const GetSortedProductOffers = (sortingInfo: SortByFieldInfo, category: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/product_offers/${category}/sorting?page=${page}` : `${BaseApiUrl}/product_offers/${category}/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedDashboardWeeklyReviews = (sortingInfo: SortByFieldInfo, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/dashboard_weekly_review/sorting?page=${page}` : `${BaseApiUrl}/dashboard_weekly_review/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const GetSortedTabArticles = (sortingInfo: SortByFieldInfo, categoryId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-articles/${categoryId}/sorting?page=${page}` : `${BaseApiUrl}/tabs-articles/${categoryId}/sorting`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}