
import axios, { AxiosResponse } from "axios";
import {
    ContactCompaniesList,
    ContactCompaniesTabItemsList,
    ContactCompaniesTabsList,
    CreateContactTabItem, DeleteContactTabItem,
    GetFoundedCompaniesTabItemsList,
    GetSortedContactCompaniesTabItemsList, UpdateContactTabItem
} from "../apiroutes/ContactCompaniesRoutes";
import {
    ContactAddressPayloadInfo, ContactBankDetailsPayloadInfo,
    ContactPersonPayloadInfo,
    SearchInfo,
    SortByFieldInfo
} from "@/store/apiPayloadsFiles/payloadsInfo";


async function getContactCompaniesList() {
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            ContactCompaniesList.method
        ](
            ContactCompaniesList.url,
            ContactCompaniesList.options
        );
        return {
            contact_companies: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function getContactTabsList(params: number) {
    const resObj = ContactCompaniesTabsList(params)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.options
        );
        return {
            contact_tabs: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function getContactTabItemsList(params: {company: number, tab: number, page?: number, link?: string}) {
    const resObj = ContactCompaniesTabItemsList(params.company, params.tab, params.page, params.link)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function getSortedContactTabItemsList(params: {sortingInfo: SortByFieldInfo, company: number, tab: number, page?: number}) {
    const resObj = GetSortedContactCompaniesTabItemsList(params.sortingInfo, params.company, params.tab, params.page)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function getLocalSearcResultItemsList(params: {searchInfo: SearchInfo, company: number, tab: number, page?: number}) {
    const resObj = GetFoundedCompaniesTabItemsList(params.searchInfo, params.company, params.tab, params.page)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function createContactPersonItem(params: {payloadInfo: ContactPersonPayloadInfo, company: number, tab: number}) {
    const resObj = CreateContactTabItem(params.payloadInfo, `contact-persons`, params.company, params.tab)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function createContactAddressItem(params: {payloadInfo: ContactAddressPayloadInfo, company: number, tab: number}) {
    const resObj = CreateContactTabItem(params.payloadInfo, `contact-addresses`, params.company, params.tab)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function createContactBankDetailsItem(params: {payloadInfo: ContactBankDetailsPayloadInfo, company: number, tab: number}) {
    const resObj = CreateContactTabItem(params.payloadInfo, `contact-bank-details`, params.company, params.tab)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function updateContactPersonItem(params: {payloadInfo: ContactPersonPayloadInfo, company: number, tab: number, item: number}) {
    const resObj = UpdateContactTabItem(params.payloadInfo, `contact-persons`, params.company, params.tab, params.item)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function updateContactAddressItem(params: {payloadInfo: ContactAddressPayloadInfo, company: number, tab: number, item: number}) {
    const resObj = UpdateContactTabItem(params.payloadInfo, `contact-addresses`, params.company, params.tab, params.item)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function updateContactBankDetailsItem(params: {payloadInfo: ContactBankDetailsPayloadInfo, company: number, tab: number, item: number}) {
    const resObj = UpdateContactTabItem(params.payloadInfo, `contact-bank-details`, params.company, params.tab, params.item)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.payload,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function deleteContactPersonItem(params: {company: number, tab: number, item: number}) {
    const resObj = DeleteContactTabItem(`contact-persons`, params.company, params.tab, params.item)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function deleteContactAddressItem(params: {company: number, tab: number, item: number}) {
    const resObj = DeleteContactTabItem(`contact-addresses`, params.company, params.tab, params.item)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}
async function deleteContactBankDetailsItem(params: {company: number, tab: number, item: number}) {
    const resObj = DeleteContactTabItem(`contact-bank-details`, params.company, params.tab, params.item)
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            resObj.method
        ](
            resObj.url,
            resObj.options
        );
        return {
            contact_tab_content: response.data
        } ;
    } catch (error) {
        throw error;
    }
}

export default {
    getContactCompaniesList,
    getContactTabsList,
    getContactTabItemsList,
    getSortedContactTabItemsList,
    getLocalSearcResultItemsList,
    createContactPersonItem,
    createContactAddressItem,
    createContactBankDetailsItem,
    updateContactPersonItem,
    updateContactAddressItem,
    updateContactBankDetailsItem,
    deleteContactPersonItem,
    deleteContactAddressItem,
    deleteContactBankDetailsItem,
};