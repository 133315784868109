import authModule from '@/store/modules/auth';
import {CreateDasboardTextSlideInfo, UpdateDasboardTextSlideInfo} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL + '/dashboard/text_slider';
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}
export const DasboardTextSlidesList = {
    url: `${BaseApiUrl}`,
    method: 'get',
    options: authoptions,
}
export const DasboardAllSimpleTextSlidesList = (page?: number) =>  {
    return {
        url: page ? `${BaseApiUrl}/all_simple?page=${page}` : `${BaseApiUrl}/all_simple`,
        method: 'get',
        options: authoptions,
    }

}
export const DasboardAllBirthdayTextSlidesList = (page?: number) =>  {
    return {
        url: page ? `${BaseApiUrl}/all_birthday?page=${page}` : `${BaseApiUrl}/all_birthday`,
        method: 'get',
        options: authoptions,
    }

}

export const CreateDasboardTextSlide = (dashboardTextSlideInfo: CreateDasboardTextSlideInfo) => {
    return {
        url: `${BaseApiUrl}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...dashboardTextSlideInfo }
    };
}

export const UpdateDasboardTextSlide = (dashboardTextSlideInfoId: number, dashboardTextSlideInfo: UpdateDasboardTextSlideInfo) => {
    return {
        url: `${BaseApiUrl}/${dashboardTextSlideInfoId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...dashboardTextSlideInfo }
    };
}

export const DeleteDasboardTextSlide = (dashboardTextSlideInfoId: number) => {
    return {
        url: `${BaseApiUrl}/${dashboardTextSlideInfoId}/delete`,
        method: 'delete',
        options: authoptions,
    };
}