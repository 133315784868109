import authModule from '@/store/modules/auth';
import {
    CreateTabArticleInfo,
    CreateTabCompanyInfo,
    CreateTabDocsInfo, CreateTabVideoInfo, SearchInfo,
    UpdateGeneralTabInfo, UpdateTabArticleInfo, UpdateTabCompanyInfo,
    UpdateTabDocsInfo, UpdateTabVideoInfo
} from "@/store/apiPayloadsFiles/payloadsInfo";

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}

export const GetGeneralTabsList = {
        url: `${BaseApiUrl}/tabs-general`,
        method: 'get',
        options: authoptions,
}

export const ShowGeneralTabItems = (tabId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-general/${tabId}?page=${page}` :`${BaseApiUrl}/tabs-general/${tabId}`,
        method: 'get',
        options: authoptions,
    };
}

export const UpdateGeneralTab = (tabId: number, tabInfo: UpdateGeneralTabInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-general/${tabId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...tabInfo }
    };
}

export const GetTabDocumentsList = (tabId: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/tabs-documents/${tabId}?page=${page}` :`${BaseApiUrl}/tabs-documents/${tabId}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    };
}

export const CreateTabDocument = (tabId: number, tabDocInfo: CreateTabDocsInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-documents/${tabId}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...tabDocInfo }
    };
}

export const UpdateTabDocument = (tabId: number, tabDocId: number, tabDocInfo: UpdateTabDocsInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-documents/${tabId}/update/${tabDocId}`,
        method: 'post',
        options: authoptions,
        payload: { ...tabDocInfo }
    };
}

export const DeleteTabDocument = (tabId: number, tabDocId: number) => {
    return {
        url: `${BaseApiUrl}/tabs-documents/${tabId}/delete/${tabDocId}`,
        method: 'delete',
        options: authoptions,
    };
}

export const GetTabCompaniesList = (tabId: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/tabs-companies/${tabId}?page=${page}` : `${BaseApiUrl}/tabs-companies/${tabId}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    };
}

export const CreateTabCompany = (tabId: number, tabCompanyInfo: CreateTabCompanyInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-companies/${tabId}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...tabCompanyInfo }
    };
}

export const UpdateTabCompany = (tabId: number, tabCompanyId: number, tabCompanyInfo: UpdateTabCompanyInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-companies/${tabId}/update/${tabCompanyId}`,
        method: 'post',
        options: authoptions,
        payload: { ...tabCompanyInfo }
    };
}

export const DeleteTabCompany = (tabId: number, tabCompanyId: number) => {
    return {
        url: `${BaseApiUrl}/tabs-companies/${tabId}/delete/${tabCompanyId}`,
        method: 'delete',
        options: authoptions,
    };
}

export const GetTabArticlesList = (tabId: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/tabs-articles/${tabId}?page=${page}` : `${BaseApiUrl}/tabs-articles/${tabId}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    };
}

export const CreateTabArticle = (tabId: number, tabArticleInfo: CreateTabArticleInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-articles/${tabId}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...tabArticleInfo }
    };
}

export const UpdateTabArticle = (tabId: number, tabArticleId: number, tabArticleInfo: UpdateTabArticleInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-articles/${tabId}/update/${tabArticleId}`,
        method: 'post',
        options: authoptions,
        payload: { ...tabArticleInfo }
    };
}

export const DeleteTabArticle = (tabId: number, tabArticleId: number) => {
    return {
        url: `${BaseApiUrl}/tabs-articles/${tabId}/delete/${tabArticleId}`,
        method: 'delete',
        options: authoptions,
    };
}

export const GetTabVideosList = (tabId: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/tabs-videos/${tabId}?page=${page}` : `${BaseApiUrl}/tabs-videos/${tabId}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    };
}

export const CreateTabVideo = (tabId: number, tabVideoInfo: CreateTabVideoInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-videos/${tabId}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...tabVideoInfo }
    };
}

export const UpdateTabVideo = (tabId: number, tabVideoId: number, tabVideoInfo: UpdateTabVideoInfo) => {
    return {
        url: `${BaseApiUrl}/tabs-videos/${tabId}/update/${tabVideoId}`,
        method: 'post',
        options: authoptions,
        payload: { ...tabVideoInfo }
    };
}

export const DeleteTabVideo = (tabId: number, tabVideoId: number) => {
    return {
        url: `${BaseApiUrl}/tabs-videos/${tabId}/delete/${tabVideoId}`,
        method: 'delete',
        options: authoptions,
    };
}

export const SearchTabsDocuments = (searchInfo: SearchInfo, tabId: number, page?: any) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-documents/${tabId}/search?page=${page}` : `${BaseApiUrl}/tabs-documents/${tabId}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}

export const SearchTabCompanies = (searchInfo: SearchInfo, tabId: number, page?: any) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-companies/${tabId}/search?page=${page}` : `${BaseApiUrl}/tabs-companies/${tabId}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}

export const SearchTabArticles = (searchInfo: SearchInfo, tabId: number, page?: any) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-articles/${tabId}/search?page=${page}` : `${BaseApiUrl}/tabs-articles/${tabId}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}

export const SearchTabVideos = (searchInfo: SearchInfo, tabId: number, page?: any) => {
    return {
        url: page ? `${BaseApiUrl}/tabs-videos/${tabId}/search?page=${page}` : `${BaseApiUrl}/tabs-videos/${tabId}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}

export const GetTabCategoryVideosList = (tabId: number, categoryId: number, page?: number) => {
    let url = page ? `${BaseApiUrl}/tabs-videos/${tabId}/category/${categoryId}?page=${page}` : `${BaseApiUrl}/tabs-videos/${tabId}/category/${categoryId}`;

    return {
        url: url,
        method: 'get',
        options: authoptions,
    };
}
