import { Module } from 'vuex';

import service from '@/store/services/dashboardTextSlide-service';
import {DashboardTextSlidesCollection} from '@/types';

interface DashboardTextSlideState {
    dashboardTextSlidesList: DashboardTextSlidesCollection[];
    dashboardTextBirthdaySlidesList: [];

}

const state: DashboardTextSlideState = {
    dashboardTextSlidesList: [],
    dashboardTextBirthdaySlidesList: [],
};

const mutations = {
    SET_DASHBOARD_TEXT_SLIDES_LIST: (state: DashboardTextSlideState, dashboardTextSlidesList: DashboardTextSlidesCollection[]) => {
        state.dashboardTextSlidesList = dashboardTextSlidesList;
    },
    SET_DASHBOARD_TEXT_BIRTHDAY_SLIDES_LIST: (state: DashboardTextSlideState, dashboardTextBirthdaySlidesList: []) => {
        state.dashboardTextBirthdaySlidesList = dashboardTextBirthdaySlidesList;
    },
};

const actions = {
    dashboardTextSlidesList({ commit, dispatch }: any, params: any) {
        return service.getdashboardTextSlides()
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
            });
    },
    dashboardAllSimpleTextSlides({ commit, dispatch }: any, params: any) {
        return service.getAllSimpleDashboardTextSlides(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
            });
    },
    dashboardAllBirthdayTextSlides({ commit, dispatch }: any, params: any) {
        return service.getAllBirthdayDashboardTextSlides(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_DASHBOARD_TEXT_BIRTHDAY_SLIDES_LIST', dashboard_text_slides);
            });
    },
    createDashboardTextSlide({ commit, dispatch }: any, params: any) {
        return service.createDasboardTextSlide(params)
            .then(({ dashboard_text_slides }: any) => {
                if (dashboard_text_slides.simple_text_slides) {
                    commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
                } else {
                    commit('SET_DASHBOARD_TEXT_BIRTHDAY_SLIDES_LIST', dashboard_text_slides);
                }

            });
    },
    updateDashboardTextSlide({ commit, dispatch }: any, params: any) {
        return service.updateDasboardTextSlide(params)
            .then(({ dashboard_text_slides }: any) => {
                if (dashboard_text_slides.simple_text_slides) {
                    commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
                } else {
                    commit('SET_DASHBOARD_TEXT_BIRTHDAY_SLIDES_LIST', dashboard_text_slides);
                }
            });
    },
    deleteDashboardTextSlide({ commit, dispatch }: any, params: any) {
        return service.deleteDasboardTextSlide(params)
            .then(({ dashboard_text_slides }: any) => {
                if (dashboard_text_slides.simple_text_slides) {
                    commit('SET_DASHBOARD_TEXT_SLIDES_LIST', dashboard_text_slides);
                } else {
                    commit('SET_DASHBOARD_TEXT_BIRTHDAY_SLIDES_LIST', dashboard_text_slides);
                }
            });
    },
};

const getters = {
    dashboardTextSlidesList: (state: DashboardTextSlideState) => state.dashboardTextSlidesList,
    dashboardTextBirthdaySlidesList: (state: DashboardTextSlideState) => state.dashboardTextBirthdaySlidesList,

};

const dashboard_text_slides: Module<DashboardTextSlideState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default dashboard_text_slides;
