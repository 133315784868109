import authModule from '@/store/modules/auth';
import {
    ContactAddressPayloadInfo, ContactBankDetailsPayloadInfo,
    ContactPersonPayloadInfo, SearchInfo,
    SortByFieldInfo
} from "@/store/apiPayloadsFiles/payloadsInfo";

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const ContactCompaniesList = {
    url: `${BaseApiUrl}/contact-companies`,
    method: 'get',
    options: authoptions,
}
export const ContactCompaniesTabsList = (company: number) => {
    return {
        url: `${BaseApiUrl}/contact-companies/${company}`,
        method: 'get',
        options: authoptions,
    }
}
export const ContactCompaniesTabItemsList = (company: number, tab: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/contact-companies/${company}/tab/${tab}?page=${page}`: `${BaseApiUrl}/${company}/tab/${tab}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    }
}

export const GetSortedContactCompaniesTabItemsList = (sortingInfo: SortByFieldInfo, company: number, tab: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/contact-companies/${company}/tab/${tab}?page=${page}` : `${BaseApiUrl}/${company}/tab/${tab}`,
        method: 'post',
        options: authoptions,
        payload: { ...sortingInfo }
    };
}

export const CreateContactTabItem = (payloadInfo: ContactPersonPayloadInfo | ContactAddressPayloadInfo | ContactBankDetailsPayloadInfo, prtial_url: string, company: number, tab: number) => {
    return {
        url: `${BaseApiUrl}/${prtial_url}/${company}/tab/${tab}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...payloadInfo }
    };
}

export const UpdateContactTabItem = (payloadInfo: ContactPersonPayloadInfo | ContactAddressPayloadInfo | ContactBankDetailsPayloadInfo, prtial_url: string, company: number, tab: number, item: number) => {
    return {
        url: `${BaseApiUrl}/${prtial_url}/${company}/tab/${tab}/update/${item}`,
        method: 'post',
        options: authoptions,
        payload: { ...payloadInfo }
    };
}

export const DeleteContactTabItem = (prtial_url: string, company: number, tab: number, item: number) => {
    return {
        url: `${BaseApiUrl}/${prtial_url}/${company}/tab/${tab}/delete/${item}`,
        method: 'delete',
        options: authoptions
    };
}


export const GetFoundedCompaniesTabItemsList = (searchInfo: SearchInfo, company: number, tab: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/contact-companies/${company}/tab/${tab}/search?page=${page}` : `${BaseApiUrl}/contact-companies/${company}/tab/${tab}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    };
}

