import type { VueElement } from "vue";
import type { AppLayoutsEnum } from "@/layouts/layouts.types";

declare module "vue-router" {
    interface RouteMeta {
        layout?: AppLayoutsEnum;
        layoutComponent?: VueElement;
    }
}

export enum RouteNamesEnum {
    dashboard = "dashboard",
    dashboard_text_slider = "dashboard_text_slider",
    dashboard_special_offer_slider = "dashboard_special_offer_slider",
    dashboard_greeting_slider = "dashboard_greeting_slider",
    contact = "contact",
    general = "general",
    management_fees = "management_fees",
    companies  = "companies",
    links  = "links",
    users = "users",
    events = "events",
    service = "service",
    category = "category",
    offers = "offers",
    search = "search",
    login = "login",
    notFound = "notFound"
}