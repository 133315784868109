import authModule from '@/store/modules/auth';
import {DashboardCaruselSliderInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const DashboardSlidersList = {
    url: `${BaseApiUrl}/dashboard/sliders`,
    method: 'get',
    options: authoptions,
}

export const UpdateDashboardSlider = (sliderId: number, sliderInfo: DashboardCaruselSliderInfo) => {
    return {
        url: `${BaseApiUrl}/dashboard/sliders/${sliderId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...sliderInfo }
    };
}