import axios, { AxiosResponse } from "axios";
import {DashboardSlidersList, UpdateDashboardSlider} from "@/store/apiroutes/DashboardCaruselRoutes";
import {DashboardCaruselSliderInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {Category} from "@/types";

async function getSlidersCarusel() {
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            DashboardSlidersList.method
        ](
            DashboardSlidersList.url,
            DashboardSlidersList.options
        );
        return {sliders: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updateDashboardCarusel(payload: {sliderInfo: DashboardCaruselSliderInfo, sliderId: number}) {
    const updateObj = UpdateDashboardSlider(payload.sliderId, payload.sliderInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<Category> = await axios[
            updateObj.method
            ](
            updateObj.url,
            updateObj.payload,
            updateObj.options
        );
        return {sliders: response.data} ;
    } catch (error) {
        throw error;
    }
}


export default {
    getSlidersCarusel,
    updateDashboardCarusel
};