import {ValidateFieldsInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import authModule from '@/store/modules/auth';
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;


// @ts-ignore
const token = await authModule.state.token;

const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}

export const ValidateFields = (fieldsInfo: ValidateFieldsInfo, userID: any) => {
    return {
        url: userID ? `${BaseApiUrl}/fields/validate/${userID}` : `${BaseApiUrl}/fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateCtegoriesFields = (fieldsInfo: ValidateFieldsInfo, categoryID: any) => {
    return {
        url: categoryID ? `${BaseApiUrl}/categories-fields/validate/${categoryID}` : `${BaseApiUrl}/categories-fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateOffersFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/offers_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateDocumentsFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/document-fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateEventsFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/events-fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateEventCtegoriesFields = (fieldsInfo: ValidateFieldsInfo, categoryID: any) => {
    return {
        url: categoryID ? `${BaseApiUrl}/event-categories-fields/validate/${categoryID}` : `${BaseApiUrl}/event-categories-fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateContactCtegoriesFields = (fieldsInfo: ValidateFieldsInfo, categoryID: any) => {
    return {
        url: categoryID ? `${BaseApiUrl}/contact-categories-fields/validate/${categoryID}` : `${BaseApiUrl}/contact-categories-fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateContactFields = (fieldsInfo: ValidateFieldsInfo, contactID: any) => {
    return {
        url: contactID ? `${BaseApiUrl}/contacts-fields/validate/${contactID}` : `${BaseApiUrl}/contacts-fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateDashboardTextSlideFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/dasboard_text_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateDashboardGreettingsTypesFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/dashboard_greeting_types_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateDashboardOffersFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/dashboard_special_offers_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateGeneralTabsFields = (fieldsInfo: ValidateFieldsInfo, tabID: number) => {
    return {
        url: `${BaseApiUrl}/tabs-general-fields/validate/${tabID}`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateTabDocsFields = (fieldsInfo: ValidateFieldsInfo, tabID: number) => {
    return {
        url: `${BaseApiUrl}/tabs-documents-fields/validate/${tabID}`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateTabVideoFields = (fieldsInfo: ValidateFieldsInfo, tabID: number) => {
    return {
        url: `${BaseApiUrl}/tabs-videos-fields/validate/${tabID}`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateTabCompaniesFields = (fieldsInfo: ValidateFieldsInfo, tabID: number, companyId?: number) => {
    return {
        url: companyId ? `${BaseApiUrl}/tabs-companies-fields/${tabID}/validate/${companyId}` :`${BaseApiUrl}/tabs-companies-fields/${tabID}/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateTabArticlesFields = (fieldsInfo: ValidateFieldsInfo, tabID: number, articleId?: number) => {
    return {
        url: articleId ? `${BaseApiUrl}/tabs-articles-fields/${tabID}/validate/${articleId}` :`${BaseApiUrl}/tabs-articles-fields/${tabID}/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateDashboardWeeklyReportFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/dashboard_weekly_report_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateContactPersonFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/contact_persons_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateContactAddressFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/contact_addresses_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateContactBankDetailFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/contact_bank_details_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}

export const ValidateCompanyInfoItemFields = (fieldsInfo: ValidateFieldsInfo) => {
    return {
        url: `${BaseApiUrl}/companies_info_fields/validate`,
        method: 'post',
        options: authoptions,
        payload: { ...fieldsInfo }
    }
}