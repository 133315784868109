import { Module } from 'vuex';

import service from '@/store/services/sorting-service';
import {
    CategoriesCollection,
    Category,
    ContactCategory, DashBoardSpetialOfferCollection, DashboardTextSlidesCollection,
    TabItemsCollection
} from "@/types";


interface SortingState {
    sortingFieldsList: [];
    sortedCategoryDocuments: Category;
    sortedContacts: ContactCategory;
    sortedTabContent: TabItemsCollection[];
    sortedCategories: CategoriesCollection[];
    sortedContactCategories: ContactCategory[];
    sortedSpecialOffers: DashBoardSpetialOfferCollection[];
    sortedTextSlides: DashboardTextSlidesCollection[];
    sortedTextBirthdaySlides: [];
    sortedProductOffers: {};
    sortedDashboardWeeklyReviews: [];
}

const state: SortingState = {
    sortingFieldsList: [],
    sortedCategoryDocuments: {
        id: 0,
        name: '',
        icon: null,
        documents: []
    },
    sortedContacts: {
        id: 0,
        title: '',
        contacts: 0
    },
    sortedTabContent: [],
    sortedCategories: [],
    sortedContactCategories: [],
    sortedSpecialOffers: [],
    sortedTextSlides: [],
    sortedTextBirthdaySlides: [],
    sortedDashboardWeeklyReviews: [],
    sortedProductOffers: {}
};

const mutations = {
    SET_SORTING_FIELDS_LIST: (state: SortingState, fields: []) => {
        state.sortingFieldsList = fields;
    },
    SET_SORTED_DOCUMENTS_LIST: (state: SortingState, category: Category) => {
        state.sortedCategoryDocuments = category;
    },
    SET_SORTED_CONTACTS_LIST: (state: SortingState, category: ContactCategory) => {
        state.sortedContacts = category;
    },
    SET_SORTED_TAB_CONTENT: (state: SortingState, items: TabItemsCollection[]) => {
        state.sortedTabContent = items;
    },
    SET_SORTED_CATEGORIES: (state: SortingState, categories: CategoriesCollection[]) => {
        state.sortedCategories = categories;
    },
    SET_SORTED_CONTACT_CATEGORIES: (state: SortingState, categories: ContactCategory[]) => {
        state.sortedContactCategories = categories;
    },
    SET_SORTED_SPECIAL_OFFERS: (state: SortingState, offers: DashBoardSpetialOfferCollection[]) => {
        state.sortedSpecialOffers = offers;
    },
    SET_SORTED_TEXT_SLIDES: (state: SortingState, dashboard_text_slides: DashboardTextSlidesCollection[]) => {
        state.sortedTextSlides = dashboard_text_slides;
    },
    SET_SORTED_TEXT_BIRTHDAY_SLIDES: (state: SortingState, dashboard_text_slides: []) => {
        state.sortedTextBirthdaySlides = dashboard_text_slides;
    },
    SET_SORTED_PRODUCT_OFFERS: (state: SortingState, sortedProductOffers: []) => {
        state.sortedProductOffers = sortedProductOffers;
    },
    SET_SORTED_DASHBOARD_WEEKLY_REVIEWS: (state: SortingState, sortedDashboardWeeklyReviews: []) => {
        state.sortedDashboardWeeklyReviews = sortedDashboardWeeklyReviews;
    },
};

const actions = {

    getUsersSortingFields({ commit, dispatch }: any, params: any) {
        return service.getUsersSortingFields()
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getDocumentsSortingFields({ commit, dispatch }: any, params: any) {
        return service.getDocumentsSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getTabsDocumentsSortingFields({ commit, dispatch }: any, params: any) {
        return service.getTabsDocumentsSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getTabsCompaniesSortingFields({ commit, dispatch }: any, params: any) {
        return service.getTabsCompaniesSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getContactsSortingFields({ commit, dispatch }: any, params: any) {
        return service.getContactsSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getCategoriesSortingFields({ commit, dispatch }: any, params: any) {
        return service.getCategoriesSortingFields()
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getTextSlidesSortingFields({ commit, dispatch }: any, params: any) {
        return service.getTextSlidesSortingFields()
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getContactCategoriesSortingFields({ commit, dispatch }: any, params: any) {
        return service.getContactCategoriesSortingFields()
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getSpecialOffersSortingFields({ commit, dispatch }: any, params: any) {
        return service.getSpecialOffersSortingFields()
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    sortDocuments({ commit, dispatch }: any, params: any) {
        return service.sortDocuments(params)
            .then(({ category }: any) => {
                commit('SET_SORTED_DOCUMENTS_LIST', category);
            });
    },

    sortContacts({ commit, dispatch }: any, params: any) {
        return service.sortContacts(params)
            .then(({ category }: any) => {
                commit('SET_SORTED_CONTACTS_LIST', category);
            });
    },

    sortTabDocuments({ commit, dispatch }: any, params: any) {
        return service.sortTabDocuments(params)
            .then(({ items }: any) => {
                commit('SET_SORTED_TAB_CONTENT', items);
            });
    },

    sortTabCompanies({ commit, dispatch }: any, params: any) {
        return service.sortTabCompanies(params)
            .then(({ items }: any) => {
                commit('SET_SORTED_TAB_CONTENT', items);
            });
    },

    sortCategories({ commit, dispatch }: any, params: any) {
        return service.sortCategories(params)
            .then(({ categories }: any) => {
                commit('SET_SORTED_CATEGORIES', categories);
            });
    },

    sortContactCategories({ commit, dispatch }: any, params: any) {
        return service.sortContactCategories(params)
            .then(({ categories }: any) => {
                commit('SET_SORTED_CONTACT_CATEGORIES', categories);
            });
    },

    sortSpecialOffers({ commit, dispatch }: any, params: any) {
        return service.sortSpecialOffers(params)
            .then(({ offers }: any) => {
                commit('SET_SORTED_SPECIAL_OFFERS', offers);
            });
    },

    sortTextSlides({ commit, dispatch }: any, params: any) {
        return service.sortTextSlides(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_SORTED_TEXT_SLIDES', dashboard_text_slides);
            });
    },

    sortBirthdayTextSlides({ commit, dispatch }: any, params: any) {
        return service.sortBirthdayTextSlides(params)
            .then(({ dashboard_text_slides }: any) => {
                commit('SET_SORTED_TEXT_BIRTHDAY_SLIDES', dashboard_text_slides);
            });
    },

    updateDocumentsSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateDocumentsSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    updateTabDocumentsSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateTabDocumentsSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    updateTabCompaniesSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateTabCompaniesSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    updateContactsSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateContactsSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    updateCategoriesSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateCategoriesSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    updateTextSlidesSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateTextSlidesSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    updateContactCategoriesSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateContactCategoriesSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    updateSpecialOffersSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateSpecialOffersSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    sortProductOffers({ commit, dispatch }: any, params: any) {
        return service.sortProductOffers(params)
            .then(({ sortedProductOffers }: any) => {
                commit('SET_SORTED_PRODUCT_OFFERS', sortedProductOffers);
            });
    },

    updateProductOffersSortingFields({ commit, dispatch }: any, params: any) {
        return service.updateProductOffersSortingFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    getDashboardWeeklyReviewsSortingFields({ commit, dispatch }: any, params: any) {
        return service.getDashboardWeeklyRewiewsFields(params)
            .then(({ fields }: any) => {
                commit('SET_SORTING_FIELDS_LIST', fields);
            });
    },

    sortDashboardWeeklyReviews({ commit, dispatch }: any, params: any) {
        return service.sortDashboardWeeklyReviews(params)
            .then(({ reviews }: any) => {
                commit('SET_SORTED_DASHBOARD_WEEKLY_REVIEWS', reviews);
            });
    },

    sortTabArticles({ commit, dispatch }: any, params: any) {
        return service.sortTabArticles(params)
            .then(({ items }: any) => {
                commit('SET_SORTED_TAB_CONTENT', items);
            });
    },
};

const getters = {
    sortingFieldsList: (state: SortingState) => state.sortingFieldsList,
    sortedCategoryDocuments: (state: SortingState) => state.sortedCategoryDocuments,
    sortedContacts: (state: SortingState) => state.sortedContacts,
    sortedTabContent: (state: SortingState) => state.sortedTabContent,
    sortedCategories: (state: SortingState) => state.sortedCategories,
    sortedContactCategories: (state: SortingState) => state.sortedContactCategories,
    sortedSpecialOffers: (state: SortingState) => state.sortedSpecialOffers,
    sortedTextSlides: (state: SortingState) => state.sortedTextSlides,
    sortedTextBirthdaySlides: (state: SortingState) => state.sortedTextBirthdaySlides,
    sortedProductOffers: (state: SortingState) => state.sortedProductOffers,
    sortedDashboardWeeklyReviews: (state: SortingState) => state.sortedDashboardWeeklyReviews,
};

const sorting_module: Module<SortingState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default sorting_module;
