import { Module } from 'vuex';

import service from '@/store/services/companies_info-service';

interface CompanyInfoState {
    companiesInfoList: [];
    companyInfoItemsList: [];
}

const state: CompanyInfoState = {
    companiesInfoList: [],
    companyInfoItemsList: [],
};

const mutations = {
    SET_COMPANIES_INFO_LIST: (state: CompanyInfoState, companiesInfoList: []) => {
        state.companiesInfoList = companiesInfoList;
    },
    SET_COMPANIES_INFO_ITEMS_LIST: (state: CompanyInfoState, companyInfoItemsList: []) => {
        state.companyInfoItemsList = companyInfoItemsList;
    }
};

const actions = {
    companiesInfoList({ commit, dispatch }: any, params: any) {
        return service.getCompaniesInfoList()
            .then(({ companies }: any) => {
                commit('SET_COMPANIES_INFO_LIST', companies);
            });
    },
    companyInfoItemsList({ commit, dispatch }: any, params: any) {
        return service.showCompanyInfoItems(params)
            .then(({ company_info_items }: any) => {
                commit('SET_COMPANIES_INFO_ITEMS_LIST', company_info_items);
            });
    },
    sortCompanyInfoItemsList({ commit, dispatch }: any, params: any) {
        return service.sortCompanyInfoItems(params)
            .then(({ company_info_items }: any) => {
                commit('SET_COMPANIES_INFO_ITEMS_LIST', company_info_items);
            });
    },
    createCompanyInfoItem({ commit, dispatch }: any, params: any) {
        return service.createCompanyInfoItem(params)
            .then(({ company_info_items }: any) => {
                commit('SET_COMPANIES_INFO_ITEMS_LIST', company_info_items);
            });
    },
    updateCompanyInfoItem({ commit, dispatch }: any, params: any) {
        return service.updateCompanyInfoItem(params)
            .then(({ company_info_items }: any) => {
                commit('SET_COMPANIES_INFO_ITEMS_LIST', company_info_items);
            });
    },
    deleteCompanyInfoItem({ commit, dispatch }: any, params: any) {
        return service.deleteCompanyInfoItem(params)
            .then(({ company_info_items }: any) => {
                commit('SET_COMPANIES_INFO_ITEMS_LIST', company_info_items);
            });
    },
    searchCompanyInfoItems({ commit, dispatch }: any, params: any) {
        return service.searchCompanyInfoItems(params)
            .then(({ company_info_items }: any) => {
                commit('SET_COMPANIES_INFO_ITEMS_LIST', company_info_items);
            });
    },
};

const getters = {
    companiesInfoList: (state: CompanyInfoState) => state.companiesInfoList,
    companyInfoItemsList: (state: CompanyInfoState) => state.companyInfoItemsList,
};

const companies_info: Module<CompanyInfoState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default companies_info;
