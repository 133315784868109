import { Module } from 'vuex';

import service from '@/store/services/productOffers_Filters-service';
import {ProductFilterCollection} from '@/types';

interface ProductOffersFiltersState {
    offersFilltersList: ProductFilterCollection[];
    offersTableHeaders: {};
    offersProductList: [];
    offerFormFields: [];
    sorted: {};
    offersCategories: [];
}

const state: ProductOffersFiltersState = {
    offersFilltersList: [],
    offersTableHeaders: {},
    offersProductList: [],
    offerFormFields: [],
    sorted: {},
    offersCategories: []
};

const mutations = {
    SET_OFFERS_FILTERS_LIST: (state: ProductOffersFiltersState, offersFiltersList: ProductFilterCollection[]) => {
        state.offersFilltersList = offersFiltersList;
    },
    SET_OFFERS_TABLE_HEADERS_LIST: (state: ProductOffersFiltersState, offersheadersList: {}) => {
        state.offersTableHeaders = offersheadersList;
    },
    SET_OFFERS_PRODUCT_LIST: (state: ProductOffersFiltersState, offersProductList: []) => {
        state.offersProductList = offersProductList;
    },
    SET_OFFERS_FORM_FIELDS: (state: ProductOffersFiltersState, offerFormFields: []) => {
        state.offerFormFields = offerFormFields;
    },
    SET_SORTING_INFO: (state: ProductOffersFiltersState, sorted: {}) => {
        state.sorted = sorted;
    },
    SET_OFFERS_CATEGORIES_LIST: (state: ProductOffersFiltersState, offersCategories: []) => {
        state.offersCategories = offersCategories;
    },
};

const actions = {
    offersFilltersList({ commit, dispatch }: any, params: any) {
        return service.getOffersFiltersList(params)
            .then(({ filters }: any) => {
                commit('SET_OFFERS_FILTERS_LIST', filters);
            });
    },
    filteredOffersProductsList({ commit, dispatch }: any, params: any) {
        return service.getFilteredProducts(params)
            .then(({ headers, products, filters, sorted }: any) => {
                commit('SET_OFFERS_FILTERS_LIST', filters);
                commit('SET_OFFERS_TABLE_HEADERS_LIST', headers);
                commit('SET_OFFERS_PRODUCT_LIST', products);
                commit('SET_SORTING_INFO', sorted);
            });
    },
    offersProductsList({ commit, dispatch }: any, params: any) {
        return service.getOffersProductsList(params)
            .then(({ products }: any) => {
                commit('SET_OFFERS_PRODUCT_LIST', products);
            });
    },
    offerFormFields({ commit, dispatch }: any, params: any) {
        return service.getOffersFormFieldsList(params)
            .then(({ fields }: any) => {
                commit('SET_OFFERS_FORM_FIELDS', fields);
            });
    },
    createProductOffer({ commit, dispatch }: any, params: any) {
        return service.createOfferProducts(params)
            .then(({ products }: any) => {
                commit('SET_OFFERS_PRODUCT_LIST', products);
            });
    },
    updateProductOffer({ commit, dispatch }: any, params: any) {
        return service.updateOfferProducts(params)
            .then(({ products }: any) => {
                commit('SET_OFFERS_PRODUCT_LIST', products);
            });
    },
    deleteProductOffer({ commit, dispatch }: any, params: any) {
        return service.deleteOfferProducts(params)
            .then(({ products }: any) => {
                commit('SET_OFFERS_PRODUCT_LIST', products);
            });
    },
    offersCategoriesList({ commit, dispatch }: any, params: any) {
        return service.getOffersCategoriesFiltersList()
            .then(({ categories }: any) => {
                commit('SET_OFFERS_CATEGORIES_LIST', categories);
            });
    },
    searchProductOffer({ commit, dispatch }: any, params: any) {
        return service.searchOfferProducts(params)
            .then(({ products }: any) => {
                commit('SET_OFFERS_PRODUCT_LIST', products);
            });
    },
};

const getters = {
    offersFilltersList: (state: ProductOffersFiltersState) => state.offersFilltersList,
    offersTableHeaders: (state: ProductOffersFiltersState) => state.offersTableHeaders,
    offersProductList: (state: ProductOffersFiltersState) => state.offersProductList,
    offerFormFields: (state: ProductOffersFiltersState) => state.offerFormFields,
    sorted: (state: ProductOffersFiltersState) => state.sorted,
    offersCategories: (state: ProductOffersFiltersState) => state.offersCategories,
};

const offersProductsFilters: Module<ProductOffersFiltersState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default offersProductsFilters;
