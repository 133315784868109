import authModule from '@/store/modules/auth';
import {FilterOffersInfo, OfferProductInfo, SearchInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;

// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
    }
}
export const FilteredProductsList = (filtersInfo: FilterOffersInfo, category: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/filtered_products/${category}?page=${page}` : `${BaseApiUrl}/filtered_products/${category}`,
        method: 'post',
        options: authoptions,
        payload: { ...filtersInfo }
    }
}
export const OfferProductsList = (category: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/products/${category}?page=${page}` : `${BaseApiUrl}/products/${category}`,
        method: 'get',
        options: authoptions
    }
}
export const OfferFormFields = (category: number) => {
    return {
        url: `${BaseApiUrl}/get_form_fields/${category}`,
        method: 'get',
        options: authoptions
    }
}


export const CreateOfferProduct = (offerProductInfo: OfferProductInfo, category: number) => {
    return {
        url: `${BaseApiUrl}/product/${category}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...offerProductInfo }
    }
}

export const UpdateOfferProduct = (offerProductInfo: OfferProductInfo, productId: number, category: number) => {
    return {
        url: `${BaseApiUrl}/product/${category}/${productId}/update`,
        method: 'post',
        options: authoptions,
        payload: { ...offerProductInfo }
    };
}

export const DeleteOfferProduct = (productId: number, category: number) => {
    return {
        url: `${BaseApiUrl}/product/${category}/${productId}/delete`,
        method: 'delete',
        options: authoptions,

    };
}

export const OfferCategoriesProductsList = {
    url: `${BaseApiUrl}/products_categories`,
    method: 'get',
    options: authoptions,
}

export const SearchOfferProducts = (searchInfo: SearchInfo, category: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/product_offers/${category}/search?page=${page}` : `${BaseApiUrl}/product_offers/${category}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}
