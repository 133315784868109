import axios, { AxiosResponse } from "axios";
import {
    AllDashboardWeeklyRewiews,
    CreateDasboardWeeklyRewiew, DeleteDasboardWeeklyRewiew,
    ShowDashboardWeeklyRewiew,
    UpdateDasboardWeeklyRewiew
} from "@/store/apiroutes/DashboardWeeklyReviewRoutes";
import {DashboardWeeklyReviewInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

async function getDasboardWeeklyReview() {
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            ShowDashboardWeeklyRewiew.method
        ](
            ShowDashboardWeeklyRewiew.url,
            ShowDashboardWeeklyRewiew.options
        );
        return {reviews: response.data} ;
    } catch (error) {
        throw error;
    }
}
async function createDashboardWeeklyReview(dashboardWeeklyReviewInfo: DashboardWeeklyReviewInfo) {

    const createObj = CreateDasboardWeeklyRewiew(dashboardWeeklyReviewInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            createObj.method
            ](
            createObj.url,
            createObj.payload,
            createObj.options
        );
        return {reviews: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function updatDashboardWeeklyReview(payload: { dashboardWeeklyReviewId: number; dashboardWeeklyReviewInfo: DashboardWeeklyReviewInfo; }) {
    const updateObj = UpdateDasboardWeeklyRewiew(payload.dashboardWeeklyReviewId, payload.dashboardWeeklyReviewInfo);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            updateObj.method
            ](
            updateObj.url,
            updateObj.payload,
            updateObj.options
        );
        return {reviews: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function deleteDashboardWeeklyReview(dashboardWeeklyReviewId: number) {
    const deleteObj = DeleteDasboardWeeklyRewiew(dashboardWeeklyReviewId);
    try {
        // @ts-ignore
        const response: AxiosResponse<DashboardTextSlidesCollection> = await axios[
            deleteObj.method
            ](
            deleteObj.url,
            deleteObj.options
        );

        return {reviews: response.data} ;
    } catch (error) {
        throw error;
    }
}

async function getAllDasboardWeeklyReviews(params?: number) {
    const allReviewsObj = AllDashboardWeeklyRewiews(params);
    try {
        // @ts-ignore
        const response: AxiosResponse<any> = await axios[
            allReviewsObj.method
            ](
            allReviewsObj.url,
            allReviewsObj.options
        );
        return {reviews: response.data} ;
    } catch (error) {
        throw error;
    }
}
export default {
    getDasboardWeeklyReview,
    createDashboardWeeklyReview,
    updatDashboardWeeklyReview,
    deleteDashboardWeeklyReview,
    getAllDasboardWeeklyReviews
};