import authModule from '@/store/modules/auth';
import {CompanyInfoPayloadInfo, SortByFieldInfo, SearchInfo} from '@/store/apiPayloadsFiles/payloadsInfo';

const BaseApiUrl = process.env.VUE_APP_API_BASE_URL;
// @ts-ignore
const token = await authModule.state.token;
const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
    }
} : {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}
export const CompaniesInfoList = {
    url: `${BaseApiUrl}/companies-info`,
    method: 'get',
    options: authoptions,
}
export const CompanyInfoItems = (companyId: number, page?: number, link?: string) => {
    let url = page ? `${BaseApiUrl}/companies-info/${companyId}?page=${page}` : `${BaseApiUrl}/companies-info/${companyId}`;
    if (link) {
        url = `${BaseApiUrl}/${link}`;
    }
    return {
        url: url,
        method: 'get',
        options: authoptions,
    }
}

export const SortedCompanyInfoItems = (sortByInfo: SortByFieldInfo, companyId: number, page?: number) => {
    let url = page ? `${BaseApiUrl}/companies-info/${companyId}?page=${page}` : `${BaseApiUrl}/companies-info/${companyId}`;
    return {
        url: url,
        method: 'post',
        options: authoptions,
        payload: { ...sortByInfo }
    };
}

export const CreateCompanyInfoItem = (companyInfoPayloadInfo: CompanyInfoPayloadInfo, companyId: number) => {

    return {
        url: `${BaseApiUrl}/companies-info/${companyId}/create`,
        method: 'post',
        options: authoptions,
        payload: { ...companyInfoPayloadInfo }
    };
}

export const UpdateCompanyInfoItem = (companyInfoPayloadInfo: CompanyInfoPayloadInfo, companyId: number, itemId: number) => {
    return {
        url: `${BaseApiUrl}/companies-info/${companyId}/update/${itemId}`,
        method: 'post',
        options: authoptions,
        payload: { ...companyInfoPayloadInfo }
    };
}

export const DeleteCompanyInfoItem = (companyId: number, itemId: number) => {
    return {
        url: `${BaseApiUrl}/companies-info/${companyId}/delete/${itemId}`,
        method: 'delete',
        options: authoptions,
    };
}

export const SearchCompanyInfoItems = (searchInfo: SearchInfo, companyId: number, page?: number) => {
    return {
        url: page ? `${BaseApiUrl}/companies-info/${companyId}/search?page=${page}` : `${BaseApiUrl}/companies-info/${companyId}/search`,
        method: 'post',
        options: authoptions,
        payload: { ...searchInfo }
    }
}