import { Module } from 'vuex';

import service from '@/store/services/dashboard_carusel-service';

interface DshboardState {
    slidersList: [];
}

const state: DshboardState = {
    slidersList: [],
};

const mutations = {
    SET_SLIDERS_LIST: (state: DshboardState, slidersList: []) => {
        state.slidersList = slidersList;
    },
};

const actions = {
    slidersList({ commit, dispatch }: any, params: any) {
        return service.getSlidersCarusel()
            .then(({ sliders }: any) => {
                commit('SET_SLIDERS_LIST', sliders);
            });
    },
    updateSlider({ commit, dispatch }: any, params: any) {
        return service.updateDashboardCarusel(params)
            .then(({ sliders }: any) => {
                commit('SET_SLIDERS_LIST', sliders);
            });
    },
};

const getters = {
    slidersList: (state: DshboardState) => state.slidersList,
};

const dashboard_carusel: Module<DshboardState, any> = {
    state,
    getters,
    actions,
    mutations,
};

export default dashboard_carusel;
